import React, { useEffect, useState } from "react";
import { Navbar, Sidebar, Footer } from "./components";
import Topbar from "./components/navigation/Topbar";
import { MobileSidebar } from "./components/navigation/MobileSidebar";
import { Head } from "components/Head";
import { FiArrowUp } from "react-icons/fi";

export const MainLayout = ({ page, children, title }) => {
  const [openSidebar, setOpenSidebar] = useState(false);
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  return (
    <div className="bg-gray-100 relative">
      {/* Scroll to top */}
      {showTopBtn && (
        <div className="fixed right-12 bottom-10 z-20">
          <button
            className="rounded-md bg-green-800 px-4 py-4"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            <FiArrowUp className="text-white h-8 w-auto" />
          </button>
        </div>
      )}
      <Head title={title} />
      <Topbar page={page} />
      <Navbar
        openSidebar={openSidebar}
        setOpenSidebar={setOpenSidebar}
        page={page}
      />
      <div className="max-w-screen-xl lg:mx-auto">
        <div className="mx-5 my-4 grid grid-cols-12 h-fit lg:gap-8">
          <div className="lg:col-span-3">
            <div className="hidden lg:block ">
              <Sidebar />
            </div>
            <div
              className={`${
                openSidebar ? "w-5/6 flex" : "w-0 hidden"
              } absolute duration-300 backdrop-blur-md bg-white/80 flex w-5/6 h-screen -my-4 `}
            >
              <div
                className={`text-black origin-left font-medium text-xl duration-300 w-fit ${
                  !openSidebar && "scale-0"
                }`}
              >
                <MobileSidebar page={page} />
              </div>
            </div>
          </div>
          <div className="col-span-12 lg:col-span-9">{children}</div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
