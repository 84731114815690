import { MainLayout } from "components/Layout";
import { ProductItem } from "components/ProductItem/ProductItem";
import { useProducts } from "features/products/api/getProducts";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useCartItems } from "../api/getCartItems";
import { CartItems } from "../components/CartItems";
import { CartSummary } from "../components/CartSummary";

export const Cart = () => {
  const cartQuery = useCartItems();
  const productsQuery = useProducts();

  console.log(cartQuery.data);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MainLayout page="cart">
      <div className="md:flex w-full gap-3">{}</div>
      <div className="my-8">
        {cartQuery.data?.cartItems?.length === 0 ? (
          <>
            <div className="flex flex-col justify-center text-center my-12 font-bold h-48 space-y-6">
              <p className="text-2xl">Your Cart is empty</p>
              <p>Check out our products to get the best deals</p>
              <Link to="/">
                <button className="py-2 px-4 bg-green-500 text-white rounded">
                  Go to products
                </button>
              </Link>
            </div>
          </>
        ) : (
          <div className="grid grid-cols-2 gap-6">
            <div className="">
              <CartItems />
            </div>
            <div className="">
              <CartSummary />
            </div>
          </div>
        )}
      </div>
      {cartQuery.data?.cartItems?.length > 0 && (
        <>
          <p className="font-bold text-xl">You might be interested</p>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            {productsQuery.data?.slice(0, 4).map((product, index) => (
              <div key={index}>
                <ProductItem product={product} />
              </div>
            ))}
          </div>
        </>
      )}
    </MainLayout>
  );
};
