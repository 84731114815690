import { MainLayout } from "components/Layout";
import { UPLOADS_API_URL_DOCS } from "config";
import { useAuth } from "lib/auth";
import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useBid } from "../api/getBid";

export const Bid = () => {
  // scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { slug } = useParams();
  const auth = useAuth();
  const bid = useBid({ slug });

  console.log(bid.data);

  return (
    <>
      <MainLayout>
        <div className="shadow-md bg-white rounded-lg mt-8 p-8">
          <div className="">
            <p className="font-bold text-4xl text-center my-4">
              {bid.data?.name}
            </p>
          </div>
          <div className="flex justify-center">
            <div className="text-gray-600 text-xl my-6 space-y-4">
              <p className="">
                Bid Opening date :{" "}
                <span className="text-green-400 font-semibold">
                  {bid.data?.bid_opening_date}
                </span>
              </p>
              <p>
                Bid Closing date:{" "}
                <span className="text-red-400 font-semibold">
                  {bid.data?.bid_closing_date}
                </span>
              </p>
            </div>
          </div>

          {/* Details section */}
          <div>
            <p className="font-bold text-xl text-center my-4">Details</p>
            <div className="my-4">
              <p className="text-gray-800 text-lg">{bid.data?.details}</p>
            </div>
            <div className="my-4 overflow-auto">
              <table className="table-auto overflow-scroll w-full text-left border-collapse">
                <thead>
                  <tr>
                    <th>Procurement Reference Number</th>
                    <th>Subject matter of Procurement</th>
                    <th>Bank Bid Security </th>
                    <th>Cost of the bid</th>
                  </tr>
                </thead>
                <tbody className="text-left ">
                  {bid.data?.bidItems?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item?.procurement_reference_number}</td>
                        <td>{item?.subject_matter}</td>
                        <td>
                          UGX {item?.bank_bid_security.toLocaleString()}/={" "}
                        </td>
                        <td>UGX {item?.bid_cost.toLocaleString()}/=</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          {/* Instructions */}
          <div className="my-4">
            <div className="">
              <p className="font-bold text-xl text-center my-4">Instructions</p>
              <ul className="list-disc list-inside">
                <li>
                  Download full bid document to get all required details about
                  the offer.
                </li>
                <li>
                  Download application form and fill in the required details.
                </li>
                <li>If you qualify, continue to pay to become a bidder</li>
              </ul>
            </div>
            <div
              className="flex flex-col md:flex-row space-y-4 md:space-y-0 space-x-0 md:space-x-4 my-8 
            items-center justify-center"
            >
              <div>
                <a
                  href={
                    UPLOADS_API_URL_DOCS + "/bids/" + bid?.data?.form?.location
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="btn text-white bg-green-500 rounded-lg shadow-sm font-extrabold px-5 py-1.5">
                    Download Bid Document
                  </button>
                </a>
              </div>
              <div>
                {auth.user && auth.user.email_verified_at ? (
                  <div className="flex flex-row md:flex-col gap-10 justify-evenly ">
                    <Link to="/serviceOrder">
                      <button
                        onClick={() => localStorage.setItem("totalCost", 50000)}
                        className="btn px-5 py-1.5 py-1 rounded-lg shadow-sm bg-green-500 text-white font-bold"
                      >
                        <span>Pay bid</span>
                      </button>
                    </Link>
                  </div>
                ) : (
                  <div>
                    <Link to="/auth/login">
                      <button className="text-green-500 rounded-lg shadow-sm border border-green-500 font-extrabold px-5 py-1.5">
                        Log in and pay for bid
                      </button>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};
