import { UPLOADS_API_URL } from "config";
import React from "react";
import { useAdverts } from "../api/getAdverts";

export default function GreatDeals() {
  const advertsQuery = useAdverts();

  return (
    <div className="relative bg-gradient-to-b from-black to-gray-700 w-full h-fit overflow-hidden">
      <div className="relative mt-3">
        {advertsQuery.data?.map((advert, index) => {
          if (advert.ad_type === "Banner") {
            return (
              <img
                src={UPLOADS_API_URL + "/adverts/" + advert.image}
                alt="computing-ad"
                className=" bg-repeat rounded-lg h-32 md:h-60 shadow-md object-cover rounded-md"
                key={index}
                loading="lazy"
              />
            );
          } else return <div key={index}></div>;
        })}
        {advertsQuery.isLoading && (
          <div className="w-full flex justify-center items-center shadow-md h-32 md:h-60 skeleton"></div>
        )}
      </div>
    </div>
  );
}
