import React from "react";
import { Link } from "react-router-dom";
import { useCartItems } from "../api/getCartItems";

export const CartSummary = () => {
  //total cost
  var totalNetCost = 0;
  var totalGrossCost = 0;

  const cartQuery = useCartItems();

  return (
    <div className="">
      <div className="py-3 px-4 shadow-lg rounded-t-md bg-white font-extrabold">
        Cart Summary
      </div>
      <div className="w-full h-fit py-5 px-5 shadow-lg rounded-b-md bg-white">
        {cartQuery?.data?.cartItems?.map((item, index) => {
          totalGrossCost =
            totalGrossCost + (item.cost_price + item.tax) * item.quantity;
          totalNetCost = totalNetCost + item.cost_price * item.quantity;
          return (
            <div
              key={index}
              className="flex justify-between border-b py-1 border-gray-300"
            >
              <h1 className="py-2 md:py-0">
                {item.product.name.length > 25
                  ? item.product.name.slice(0, 22) + "..."
                  : item.product.name}
              </h1>
              <span className="font-extrabold uppercase py-2 md:py-0">
                ugx {(item.cost_price * item.quantity).toLocaleString()}
              </span>
            </div>
          );
        })}
        <div>
          <div className="flex justify-between my-8">
            <h1 className="font-extrabold">Total</h1>
            <span className="font-extrabold uppercase">
              ugx {totalNetCost.toLocaleString()}
            </span>
          </div>
          <div className="flex mt-3 justify-between">
            <div></div>
            <Link to="/order">
              <div className="py-2 px-4 bg-green-600 text-white font-extrabold cursor-pointer">
                Check Out UGX {totalGrossCost.toLocaleString()}
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
