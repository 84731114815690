import { MainLayout } from "components/Layout";
import React from "react";
import { useOrders } from "../api/getOrders";
import { RemoveOrder } from "../components/DeleteOrder";

export const Orders = () => {
  const ordersQuery = useOrders();

  return (
    <MainLayout>
      <div>
        <div className="px-8 py-4 shadow-lg rounded-md bg-white">
          <p className="font-bold text-lg">Orders List</p>
        </div>

        {ordersQuery?.data?.map((order, index) => (
          <div
            className="my-6 px-8 py-4 bg-white rounded-md shadow-lg"
            key={index}
          >
            <div className="flex justify-between my-8">
              <div>
                <p className="text-lg font-semibold text-gray-600">
                  Order: {order?.id}
                </p>
                <p className="font-semibold text-gray-600">
                  Date: {new Date(order?.created_at).toLocaleString()}
                </p>
              </div>
              <RemoveOrder orderId={order?.id} />
            </div>
            <div className="flex flex-col md:flex-row md:justify-between md:items-start ">
              <div>
                <p className="text-lg my-2">Payment method</p>
                <p className="text-lg my-2">{order?.paymentMethod}</p>
              </div>
              <div>
                <p className="text-lg my-2">Total Amount</p>
                <p className="text-gray-500 font-semibold">
                  sh. {order?.totalPrice.toLocaleString()}
                </p>
              </div>
              <div>
                <p className="text-lg my-2">Status</p>

                {order?.isPaid === 0 ? (
                  <p className="bg-red-500 text-white w-fit px-4 py-2 rounded-md text-center">
                    Unpaid
                  </p>
                ) : (
                  <p className="bg-green-500 text-white w-fit px-4 py-2 rounded-md text-center">
                    Paid
                  </p>
                )}
              </div>
            </div>

            {/* Order Item */}
            <table class="table-auto w-full my-8">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Quantity</th>
                  <th>Cost</th>
                </tr>
              </thead>
              <tbody>
                {order?.orderItems.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item?.name}</td>
                      <td>{item?.quantity}</td>
                      <td>sh. {item?.totalPrice.toLocaleString()}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ))}

        {/* Skeleton */}
        {ordersQuery.isLoading && (
          <div>
            <div className="shadow-lg rounded-md my-4 w-full bg-white my-4 px-8 py-4">
              <p className="text-lg font-semibold text-gray-600">Order: </p>
              <div className="w-full flex flex-col md:flex-row md:items-center md:justify-between">
                <div>
                  <p>Payment Method</p>
                  <div className="skeleton h-8 my-2"></div>
                </div>
                <div>
                  <p>Total Amount</p>
                  <div className="skeleton h-8 my-2"></div>
                </div>
                <div>
                  <p>Status</p>
                  <div className="skeleton h-8 my-2"></div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </MainLayout>
  );
};
