import { Button } from "components/Elements";
import { Form, InputField } from "components/Form";
import { useAuth } from "lib/auth";
import PropTypes from "prop-types";
import * as React from "react";
import { Link } from "react-router-dom";

export const RegisterForm = ({ onSuccess }) => {
  const { register, isRegistering } = useAuth();

  return (
    <div>
      <Form
        onSubmit={async (values) => {
          // add user role to values
          values.role = "USER";
          await register(values);
          onSuccess();
        }}
        options={{
          shouldUnregister: true,
        }}
      >
        {({ register, formState }) => (
          <>
            <InputField
              type="text"
              label="Name"
              error={formState.errors["name"]}
              registration={register("name")}
            />
            <InputField
              type="email"
              label="Email Address"
              error={formState.errors["email"]}
              registration={register("email")}
            />
            <InputField
              type="number"
              label="Phone Number"
              placeholder="+256782692271"
              error={formState.errors["phone_number"]}
              registration={register("phone_number")}
            />
            <InputField
              type="password"
              label="Password"
              error={formState.errors["password"]}
              registration={register("password")}
            />
            <div>
              <Button
                isLoading={isRegistering}
                type="submit"
                className={`w-full ${
                  isRegistering ? "bg-green-400" : "bg-green-600"
                }`}
              >
                {isRegistering ? "Registering..." : "Register"}
              </Button>
            </div>
          </>
        )}
      </Form>
      <div className="mt-2 flex items-center justify-end">
        <div className="text-sm">
          <Link
            to="/auth/login"
            className="font-medium text-green-600 hover:text-green-500"
          >
            Already have an account? Login
          </Link>
        </div>
      </div>
    </div>
  );
};

RegisterForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};
