import { axios } from "lib/axios";
import { useQuery } from "react-query";

export const getEvaluation = () => {
  return axios.get("/evaluations");
};

export const useEvaluation = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["evaluations"],
    queryFn: () => getEvaluation(),
  });
};
