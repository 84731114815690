import { MainLayout } from "components/Layout";
import { Link } from "react-router-dom";
import { useBids } from "../api/getBids";
// import { BidItem } from "components/BidItem/BidItem";

export const Bids = () => {
  const bidsQuery = useBids();

  return (
    <MainLayout page="Bids">
      <div className="space-y-6">
        <div className="flex px-4 md:px-8 py-5 items-center justify-between">
          <div>
            <h1 className="text-sm md:text-base">
              Services: <span className="font-extrabold ">Bids</span>
            </h1>
          </div>
        </div>
        <div className="w-full">
          {/* display bids */}
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {bidsQuery?.data?.map((bid, index) => {
                return (
                  <div key={index} className="flex items-center gap-3">
                    <div className="bg-white rounded-md shadow-lg p-3 space-y-8">
                      <div>
                        <p className="font-bold text-2xl text-center my-4">
                          {bid?.name}
                        </p>
                      </div>
                      <div>
                        <p className="text-gray-600">
                          {bid?.details?.length > 125
                            ? bid?.details?.slice(0, 122) + "..."
                            : bid?.details}
                        </p>
                      </div>
                      <div className="text-gray-600 my-6">
                        <p className="">
                          Bid Opening date :{" "}
                          <span className="text-green-400 font-semibold">
                            {bid?.bid_opening_date}
                          </span>
                        </p>
                        <p>
                          Bid Closing date:{" "}
                          <span className="text-red-400 font-semibold">
                            {bid?.bid_closing_date}
                          </span>
                        </p>
                      </div>
                      <div>
                        <Link to={`/services/bids/${bid?.slug}`}>
                          <button
                            className="py-3 px-5 hover:bg-green-800 duration-200 transition ease-in-out delay-150
                   rounded-md  mx-auto w-full bg-green-600 text-white text-center"
                          >
                            View Bid offer
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
              {/* Skeleton  */}
              {bidsQuery.isLoading && (
                <div className="">
                  <div className="bg-white rounded-md shadow-lg p-3 space-y-8">
                    <div>
                      <div className="skeleton h-8 w-full">
                        <p></p>
                      </div>
                    </div>
                    <div>
                      <div className="my-4 space-y-4">
                        <div className="skeleton w-2/3 h-6"></div>
                        <div className="skeleton w-2/3 h-6"></div>
                        <div className="skeleton w-2/3 h-6"></div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        </div>
      </div>
    </MainLayout>
  );
};
