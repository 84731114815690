import { MainLayout } from "components/Layout";
import { useAuth } from "lib/auth";
import { useEvaluation } from "../api/getBookEvaluation";
import { BookToEvaluate } from "../components/BookToEvaluate";
import { LogError } from "../components/LogError";
import { NoBook } from "../components/NoBook";

export const BookEvaluation = () => {
  const auth = useAuth();
  const evaluationQuery = useEvaluation();

  const evaluationBook = evaluationQuery.data?.filter((bookEvaluation) => {
    return bookEvaluation?.user_id === auth?.user?.id;
  });

  return (
    <MainLayout>
      <div className="flex flex-col px-4 md:px-8 py-5 justify-between">
        <div>
          <h1 className="text-sm md:text-base">
            Services: <span className="font-extrabold ">Book Evaluation</span>
          </h1>
        </div>
        <div className="flex items-center ">
          {auth.user && auth.user.email_verified_at ? (
            evaluationBook?.length !== 0 ? (
              <BookToEvaluate books={evaluationBook} />
            ) : (
              <NoBook />
            )
          ) : (
            <LogError />
          )}
        </div>
      </div>
    </MainLayout>
  );
};
