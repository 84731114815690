import { Spinner } from "components/Elements";
import React from "react";
import { useAddCartQuantity } from "../api/addCartQuantity";

export const AddCartQuantity = ({ item }) => {
  const addCartQuantityMutation = useAddCartQuantity();
  return (
    <button
      onClick={async () =>
        await addCartQuantityMutation.mutateAsync({
          cartItemId: item.id,
          data: {
            quantity: item.quantity + 1,
          },
        })
      }
      className="bg-green-500 px-2 text-xl text-white"
    >
      {addCartQuantityMutation.isLoading ? <Spinner /> : <span>+</span>}
    </button>
  );
};
