import { Contact } from "features/services/components/Contact";
import React, { useState } from "react";
import { FiXCircle } from "react-icons/fi";

export const NoBook = () => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      {openModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto 
            fixed inset-0 z-50 transition ease-in-out delay-300 duration-1000 bg-black-0.1"
          >
            <div className="relative w-5/6 md:w-1/2 lg:w-1/3 my-6 mx-auto max-w-5xl">
              {/*content*/}
              <div className="bg-white w-full rounded-md shadow-lg px-4">
                <div className="flex items-center justify-between p-4 ">
                  <div>
                    <p className="text-2xl text-center font-semibold text-gray-800">
                      CONTACT US
                    </p>
                  </div>
                  <div>
                    <button
                      type="button"
                      onClick={() => setOpenModal(false)}
                      className="w-4 h-4 p-1 text-black border-none rounded-none 
                      opacity-50 hover:text-green-600 hover:opacity-75 hover:no-underline"
                    >
                      <FiXCircle className="w-7 h-7" />
                    </button>
                  </div>
                </div>
                <Contact />
              </div>
            </div>
          </div>
          <div className="opacity-30 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

      {/* For people who aren't evaluated */}
      <div className="w-full">
        <div className="flex flex-col items-center justify-center">
          <p className="my-12 text-xl">
            Sorry, you are not subscribed to the services.
          </p>
          <p className="my-4 text-lg">
            Please contact us with you want your work to be evaluated by NCDC
          </p>
          <button
            className="text-white bg-green-500 rounded-lg hover:bg-green-800 shadow-sm font-extrabold px-5 py-1.5"
            onClick={() => setOpenModal(true)}
          >
            Contact us
          </button>
        </div>
      </div>
    </>
  );
};
