import React from "react";
import { useProducts } from "../api/getProducts";
import { ProductItem } from "components/ProductItem/ProductItem";

export default function TrendingItems() {
  //get data from API
  const trendsQuery = useProducts();

  return (
    <div>
      <div className="">
        <div className="flex flex-col md:flex-row justify-between md:items-center mb-2">
          <div className="mt-5 flex items-center justify-between gap-2">
            <h1 className="uppercase text-md font-black text-green-600">
              Trending Items
            </h1>
            <div className="flex gap-1">
              <div className="py-1 px-3 bg-green-500 rounded-full"></div>
              <div className="py-1 px-1 bg-gray-500 rounded-full"></div>
              <div className="py-1 px-1 bg-gray-500 rounded-full"></div>
            </div>
          </div>
        </div>
        <div className="w-full">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
            {trendsQuery.data?.slice(0, 9)?.map((product, index) => {
              return (
                <div key={index}>
                  <ProductItem product={product} />
                </div>
              );
            })}

            {/* skeleton  */}
            {trendsQuery.isLoading &&
              [0, 1, 2, 3].map((index) => {
                return (
                  <div key={index}>
                    <div className="flex items-center gap-y-3 h-full rounded-lg shadow-md bg-white">
                      <div className="rounded-lg space-y-2 w-full">
                        <div className="skeleton h-48 w-full"></div>
                        <div className="p-4">
                          <div className="skeleton h-6 w-full"></div>
                          <div className="skeleton h-6 w-3/4 my-2"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          {trendsQuery.data?.length === 0 && (
            <div className="">
              <p className="text-xl text-center font-bold my-4">
                No Products available
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
