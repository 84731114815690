/** @format */

import React, { Fragment, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import { MdFavoriteBorder } from "react-icons/md";
import { HiOutlineMenu } from "react-icons/hi";
import { useAuth } from "lib/auth";
import { ChevronDown, Lock } from "react-feather";
import { Menu, Transition } from "@headlessui/react";
import { useProducts } from "../api/getBooks";
import { Spinner, TableFloating } from "components/Elements";
import { UPLOADS_API_URL } from "config";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { CartItem } from "./components/CartItem";

export const Navbar = ({ openSidebar, setOpenSidebar, page }) => {
  const auth = useAuth();

  const productsQuery = useProducts();
  const inputRef = useRef(null);

  const [q, setQ] = useState("");
  const [searchColumns, setSearchColumns] = useState(["slug"]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const columns = [
    {
      title: "",
      field: "name",
      Cell({ entry: { slug, name, defaultImage } }) {
        return (
          <Link to={`/products/${slug}`} className="flex flex-row items-center">
            {defaultImage && (
              <LazyLoadImage
                src={`${UPLOADS_API_URL}/${defaultImage?.name}`}
                alt="default image for product"
                className="h-16 hidden md:block rounded-lg mr-4"
              />
            )}
            <div className="">
              <div className="w-[80vw] overflow-hidden">
                <p className="break-words text-gray-800">{name}</p>
              </div>
            </div>
          </Link>
        );
      },
    },
  ];

  function search(rows) {
    return rows?.filter((row) =>
      searchColumns.some(
        (column) =>
          row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
      )
    );
  }

  const products = search(productsQuery.data);

  return (
    <>
      <div className="bg-green-700 shadow-sm">
        <div className="max-w-screen-xl mx-auto">
          <div className="mx-5 md:mx-10 pt-3 flex md:justify-between items-start">
            <div className="w-11/12 lg:w-5/12">
              <div className="input-group flex justify-between gap-4 md:gap-0 items-center lg:items-start lg:w-96 mb-4">
                <div className="lg:hidden">
                  <HiOutlineMenu
                    className="text-2xl text-green-100 cursor-pointer"
                    onClick={() => setOpenSidebar(!openSidebar)}
                  />
                </div>
                <div className="flex w-60 lg:w-full">
                  <input
                    type="search"
                    className="flex-auto min-w-0 rounded-l-full px-4 py-2 text-xs md:text-base text-white placeholder-green-200 bg-green-600  m-0  focus:outline-none"
                    placeholder="Search for product..."
                    value={q}
                    onChange={(e) => setQ(e.target.value)}
                    ref={inputRef}
                  />

                  <Link
                    to="/"
                    className={
                      page === "search"
                        ? "btn px-3 py-2 bg-green-500 text-white hover:bg-green-800 focus:outline-none flex items-center rounded-r-full"
                        : "btn px-3 py-2 bg-green-500 text-white hover:bg-green-800 focus:outline-none flex items-center rounded-r-full"
                    }
                  >
                    <button>
                      <FiSearch className="text-md" />
                    </button>
                  </Link>
                </div>
                <div
                  className={`h-fit mt-10 absolute shadow-md rounded-lg bg-white p-4 flex w-64 md:w-96 ${
                    q ? "" : "hidden"
                  }`}
                >
                  <TableFloating data={products} columns={columns} />
                  {productsQuery.isLoading && (
                    <div>
                      <Spinner />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <p className="hidden md:flex lg:hidden text-green-200 py-2 px-6 text-md flex items-center rounded-sm gap-2">
              <Link to="/" className="text-xl hover:text-white">
                Home
              </Link>
            </p>
            <div className="hidden lg:flex justify-end items-start w-6/12 ">
              <p className="text-green-200 hover:shadow-xl hover:text-white py-2 px-2 text-md flex items-center rounded-sm gap-2">
                <Link to="/" className="text-xl hover:text-white">
                  Home
                </Link>
              </p>
              {auth.user && auth.user.email_verified_at ? (
                <>
                  <div>
                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <Menu.Button className=" hover:text-white hover:shadow-xl inline-flex w-full text-gray-200 items-center justify-center rounded-md border-none bg-none px-4 py-2 text-lg text-green-200  hover:text-white focus:outline-none">
                          {/* rounded avatar initial */}
                          <div className="flex items-center mr-2 justify-center h-6 w-6 rounded-full bg-green-500 text-white text-xs font-medium">
                            {auth.user.name[0]}
                          </div>
                          <span>{auth.user.name}</span>
                          <ChevronDown className="mx-2" />
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to="/profile"
                                  className={classNames(
                                    active
                                      ? "bg-green-100 text-gray-900"
                                      : "text-gray-700",
                                    "block px-4 py-2 text-md"
                                  )}
                                >
                                  My Profile
                                </Link>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to="/profile/orders"
                                  className={classNames(
                                    active
                                      ? "bg-green-100 text-gray-900"
                                      : "text-gray-700",
                                    "block px-4 py-2 text-md"
                                  )}
                                >
                                  My Orders
                                </Link>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  onClick={() => auth.logout()}
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-gray-900"
                                      : "text-gray-700",
                                    "block w-full px-4 py-2 text-left text-md"
                                  )}
                                >
                                  Sign out
                                </button>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                  <Link
                    to="/wishlist"
                    className={page === "wishlist" ? "" : ""}
                  >
                    <div className="hover:text-white hover:shadow-xl text-green-200 py-2 px-2 text-md flex items-center rounded-sm gap-2">
                      <MdFavoriteBorder className="text-green-200 hover:text-white text-xl font-bold cursor-pointer" />
                      <h1 className="text-green-200 hover:text-white cursor-pointer">
                        Wishlist
                      </h1>
                    </div>
                  </Link>
                  <Link to="/cart">
                    <CartItem />
                  </Link>
                </>
              ) : (
                <Link to="/auth/login" className={page === "login" ? "" : ""}>
                  <div className="text-green-200 py-2 px-2 text-lg flex items-center rounded-sm gap-2">
                    <Lock className="text-green-200 font-bold cursor-pointer" />
                    <h1 className="text-green-200 cursor-pointer hover:text-white">
                      Login / Register
                    </h1>
                  </div>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
