import { Orders, Profile } from "features/account";
import { Cart } from "features/cart";
import { Checkout, Payment } from "features/checkout";
import { Receipt } from "features/checkout/routes/Receipt";
import { NotFound } from "features/misc";
import { ServiceOrder } from "features/services/components/ServiceOrder";
import { Wishlist } from "features/wishlist";

export const protectedRoutes = [
  {
    path: "/wishlist",
    element: <Wishlist />,
  },
  {
    path: "/profile",
    element: <Profile />,
  },
  {
    path: "/profile/orders",
    element: <Orders />,
  },
  {
    path: "/cart",
    element: <Cart />,
  },
  {
    path: "/payment",
    element: <Payment />,
  },
  {
    path: "/order",
    element: <Checkout />,
  },
  {
    path: "/receipt",
    element: <Receipt />,
  },
  { path: "*", element: <NotFound /> },
  { path: "/serviceOrder", element: <ServiceOrder /> },
];
