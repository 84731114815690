import React from "react";
import { useWishlist } from "../api/getWishlist";
import { UPLOADS_API_URL } from "config";
import { AddToCartBtn } from "./AddToCartBtn";
import { RemoveWishlist } from "./RemoveWishlist";
import { Link } from "react-router-dom";

export const WishlistCard = () => {
  const wishlistQuery = useWishlist();

  return (
    <div>
      {wishlistQuery?.data?.wishlistItems?.map((item, index) => {
        return (
          <div
            key={index}
            className="shadow-lg rounded-md my-8 w-full md:h-44 bg-white"
          >
            <div className="flex">
              <div className="w-2/12 md:h-full">
                <img
                  src={
                    UPLOADS_API_URL +
                    "/products/" +
                    item.product?.image?.location
                  }
                  alt="..."
                  className="h-40"
                  loading="lazy"
                />
              </div>
              <div className="w-10/12">
                <div className="flex justify-between py-2 px-5">
                  <div className="py-4 space-y-4">
                    <Link to={`/products/${item.product?.slug}`}>
                      <h1 className="font-extrabold text-xl">
                        {item.product.name.length > 38
                          ? item.product.name.slice(0, 35) + "..."
                          : item.product.name}
                      </h1>
                    </Link>
                    <div className="space-y-4">
                      <span className="font-extrabold uppercase text-xl text-red-700">
                        Ugx: {item.product?.amount.toLocaleString()}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col space-y-8 py-4 items-end">
                    <div>
                      <AddToCartBtn productId={item.product?.id} />
                    </div>
                    <div>
                      <RemoveWishlist wishlistItemId={item?.id} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}

      {/* Skeleton */}
      {wishlistQuery.isLoading &&
        [0, 1].map((index) => (
          <div
            key={index}
            className="shadow-lg rounded-md my-4 w-full bg-white flex item-center my-8"
          >
            <div className="skeleton h-40 w-48"></div>
            <div className="w-full">
              <div className="skeleton h-8 w-3/4 m-8"></div>
              <div className="skeleton h-8 w-2/4 m-8"></div>
            </div>
          </div>
        ))}

      <div
        className={
          wishlistQuery?.data?.wishlistItems?.length === 0 ? "flex" : "hidden"
        }
      >
        <p className="text-center w-full py-8">No items in the wishlist</p>
      </div>
    </div>
  );
};
