// import { Pagination } from 'components/elements/Pagination';
import { MainLayout } from "components/Layout";
import React, { useEffect } from "react";
import { BsListUl } from "react-icons/bs";
import { WishlistCard } from "../components/WishlistCard";

export const Wishlist = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MainLayout page="wishlist">
      <div className="bg-gray-100">
        <div className="w-full">
          <div className="">
            <div className="flex justify-between p-5 shadow-lg rounded-md bg-white">
              <p className="font-extrabold">Wishlist</p>
              <div>
                <BsListUl className="text-2xl cursor-pointer" />
              </div>
            </div>
          </div>

          <div className="my-3">
            <WishlistCard />
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
