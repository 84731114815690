import React from "react";
import { Link } from "react-router-dom";

export const BookToEvaluate = ({ books }) => {
  return (
    <>
      {/* For people that submitted the book to be evaluated */}
      <div className="w-1/2 mx-auto mt-32">
        {books?.map((bookEvaluation, index) => {
          return (
            <div key={index}>
              <div className="flex gap-4">
                <div className="text-lg font-bold">
                  <p>Book Title: </p>
                </div>
                <div>
                  <p>{bookEvaluation?.title}</p>
                </div>
              </div>
              <div className="flex gap-4">
                <div className="text-lg font-bold">
                  <p>Comments: </p>
                </div>
                <div>
                  <p>{bookEvaluation?.comments}</p>
                </div>
              </div>
              <div className="flex gap-4">
                <div className="text-lg font-bold">
                  <p>Price: </p>
                </div>
                <div>
                  <p>sh. {bookEvaluation?.cost.toLocaleString()}</p>
                </div>
              </div>
              <div className="flex gap-4">
                <div className="text-lg font-bold">
                  <p>Complete in : </p>
                </div>
                <div>
                  <p>{bookEvaluation?.period_of_delivery} days</p>
                </div>
              </div>
              <Link to="/serviceOrder">
                <button
                  className="my-4 text-white bg-green-500 rounded-lg shadow-sm font-extrabold px-5 py-1.5"
                  onClick={() => {
                    localStorage.setItem(
                      "evaluationBook",
                      JSON.stringify(bookEvaluation)
                    );
                  }}
                >
                  Pay evaluation fee
                </button>
              </Link>
            </div>
          );
        })}
      </div>
    </>
  );
};
