import { Head } from "components/Head";
import logo from "assets/logo.png";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export const Layout = ({ children, title }) => {
  return (
    <>
      <Head title={title} />
      <div className="flex flex-col justify-center py-6 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <Link to="/">
            <img
              src={logo}
              className="h-24 mx-auto my-12"
              loading="lazy"
              alt="logo"
            />
          </Link>
          <h2 className="mt-3 text-center text-3xl font-extrabold text-gray-900">
            {title}
          </h2>
        </div>
        <div className="sm:mx-auto sm:w-full sm:max-w-md mt-4">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            {children}
          </div>
        </div>
        <div className="w-1/3 mx-auto my-4">
          <p className="text-center text-gray-600">
            For further support, you may visit the Help Center or contact our
            customer service team.
          </p>
        </div>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};
