/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import shoppingCart from "../../../assets/shopping-cart.png";

import {
  FaLinkedinIn,
  FaFacebookF,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";

import mtn from "../../../assets/mtn.png";
import airtel from "../../../assets/airtel.png";
import visa from "../../../assets/visa.png";
import { IPR } from "./content/IPR";
import { Dispute } from "./content/Dispute";
import { Privacy } from "./content/Privacy";
import { TC } from "./content/TC";
import { Contact } from "./content/Contact";
import { How } from "./content/How";

export const Footer = () => {
  const [currentYear, setCurrentYear] = React.useState(
    new Date().getFullYear()
  );

  const [modal, setModal] = useState({ id: "", title: "", body: `` });

  return (
    <div className="bg-footer-color text-xl">
      <div className="max-w-screen-xl lg:flex lg:mx-auto">
        <div className="w-full">
          <div className="mx-4 md:mx-10 pt-4 py-5 md:flex justify-between">
            <div>
              <h1 className="text-green-600 uppercase font-extrabold ">
                Let us help you
              </h1>
              <ul className="text-sm text-white mt-5 space-y-4 text-lg">
                <li className="mt-2 hover:text-green-600 cursor-pointer">
                  {" "}
                  <button
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#contactModal"
                    onClick={() =>
                      setModal({
                        id: "contactModal",
                        title: "Contact us",
                        body: <Contact />,
                      })
                    }
                  >
                    Help Center
                  </button>
                </li>
                <li className="mt-2 hover:text-green-600 cursor-pointer">
                  {" "}
                  <button
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#buyModal"
                    onClick={() =>
                      setModal({
                        id: "buyModal",
                        title: "How to Buy",
                        body: <How />,
                      })
                    }
                  >
                    How to buy on NCDC eShop
                  </button>
                </li>
                <li className="mt-2 hover:text-green-600 cursor-pointer">
                  {" "}
                  <button
                    onClick={() =>
                      setModal({
                        id: "contactModal",
                        title: "Contact us",
                        body: <Contact />,
                      })
                    }
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#contactModal"
                  >
                    Report a Product
                  </button>
                </li>
              </ul>
            </div>

            <div className="mt-5 md:mt-0">
              <h1 className="text-green-600 uppercase font-extrabold ">
                About NCDC E-Shop
              </h1>
              <ul className="text-sm text-white mt-5 space-y-4 text-lg">
                <li className="mt-2 hover:text-green-600 cursor-pointer">
                  {" "}
                  <button
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#iprModal"
                    onClick={() =>
                      setModal({
                        id: "iprModal",
                        title: "IPR PROTECTION POLICY",
                        body: <IPR />,
                      })
                    }
                  >
                    IPR Protection Policy
                  </button>
                </li>
                <li className="mt-2 hover:text-green-600 cursor-pointer">
                  {" "}
                  <button
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#disputeModal"
                    onClick={() =>
                      setModal({
                        id: "disputeModal",
                        title: "Dispute Resolution Policy for NCDC",
                        body: <Dispute />,
                      })
                    }
                  >
                    Dispute Resolution Policy
                  </button>
                </li>
                <li className="mt-2 hover:text-green-600 cursor-pointer">
                  {" "}
                  <button
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#privacyModal"
                    onClick={() =>
                      setModal({
                        id: "privacyModal",
                        title: "Privacy Policy for NCDC",
                        body: <Privacy />,
                      })
                    }
                  >
                    Privacy Policy
                  </button>
                </li>
                <li className="mt-2 hover:text-green-600 cursor-pointer">
                  {" "}
                  <button
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#tcModal"
                    onClick={() =>
                      setModal({
                        id: "tcModal",
                        title: "Terms and Conditions",
                        body: <TC />,
                      })
                    }
                  >
                    Terms and Conditions
                  </button>
                </li>
              </ul>
            </div>

            <div className="mt-5 md:mt-0">
              <h1 className="text-green-600 uppercase font-extrabold ">
                Payment Methods
              </h1>
              <ul className="text-sm text-white mt-5 space-y-4 text-lg">
                <li className="mt-2 flex items-center gap-1 hover:text-green-600 ">
                  <img src={mtn} alt="mtn" />
                  MTN Mobile Money
                </li>
                <li className="mt-2 flex items-center gap-1 hover:text-green-600 ">
                  <img src={airtel} alt="airtel" />
                  Airtel Money
                </li>
                <li className="mt-2 flex items-center gap-1 hover:text-green-600 ">
                  <img src={visa} alt="visa" />
                  Visa Card
                </li>
              </ul>
            </div>

            <div className="mt-5 md:mt-0">
              <h1 className="text-green-600 uppercase font-extrabold ">
                Follow us
              </h1>
              <ul className="text-sm text-white mt-5 space-y-4 text-lg">
                <a
                  href="https://www.facebook.com/NCDCUg/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <li className="my-4 flex items-center gap-1 cursor-pointer hover:text-green-600">
                    {" "}
                    <FaFacebookF /> Facebook
                  </li>
                </a>
                <a
                  href="https://twitter.com/NCDCUg"
                  target="_blank"
                  rel="noreferrer"
                >
                  <li className="my-4 flex items-center gap-1 cursor-pointer hover:text-green-600">
                    <FaTwitter />
                    Twitter
                  </li>
                </a>
                <a
                  href="https://www.youtube.com/c/NCDCUgEduc"
                  target="_blank"
                  rel="noreferrer"
                >
                  <li className="my-4 flex items-center gap-1 cursor-pointer hover:text-green-600">
                    <FaYoutube /> YouTube
                  </li>
                </a>
                <a
                  href="https://www.linkedin.com/company/national-curriculum-development-centre/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <li className="my-4 flex items-center gap-1 cursor-pointer hover:text-green-600">
                    <FaLinkedinIn />
                    Linkedin
                  </li>
                </a>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center gap-5 pb-10 pt-5 ">
        <div className="flex gap-2">
          <img src={shoppingCart} alt="shopping-cart" className="" />
          <h1 className="text-3xl text-white font-extrabold">eShop</h1>
        </div>
      </div>

      {/* Modals */}
      <div
        className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none 
        overflow-x-hidden overflow-y-auto"
        id={modal?.id || ""}
        tabIndex="-1"
        aria-labelledby="simpleModal"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable relative
         w-auto pointer-events-none"
        >
          <div
            className="modal-content border-none shadow-lg relative flex flex-col w-full 
          pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current"
          >
            <div
              className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b
             border-gray-200 rounded-t-md"
            >
              <h5
                className="text-xl font-medium leading-normal text-gray-800 text-center w-full"
                id="simpleModal"
              >
                {modal?.title}
              </h5>
              <button
                type="button"
                className="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none 
                opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black 
                hover:opacity-75 hover:no-underline"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body relative p-4">{modal?.body}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
