import React from "react";

export const Visa = () => {
  const link =
    "https://testpmtservice.ura.go.ug/paymentController/showHistory?actionCode=SHOWPMTHST&searchPrnNo=" +
    localStorage.getItem("prn");

  return (
    <>
      <div className="py-5">
        <div className="space-y-4">
          <p>
            Thank you for choosing to make a payment with your Visa card! In
            order to complete your payment, you will need to click the "Pay Now"
            button below. This will redirect you to our secure payment gateway
            where you can enter your Visa card details and complete the payment
            process.
          </p>
          <a href={link} target="_blank" rel="noopener noreferrer">
            <button
              className="hover:bg-green-600 bg-green-800 text-white text-lg 
          rounded-md px-4 py-2 text-center my-6"
            >
              Pay now
            </button>
          </a>
          <div
            className="flex p-4 mb-4 text-sm text-blue-800 border border-blue-300 
                    rounded-lg bg-blue-50"
            role="alert"
          >
            <svg
              aria-hidden="true"
              className="flex-shrink-0 inline w-5 h-5 mr-3"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 
                        0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Info</span>
            <div>
              <p className="text-sm">
                Please make sure to have your Visa card details ready before
                clicking the "Pay Now" button. If you have any questions or
                concerns, please don't hesitate to contact our customer support
                team.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
