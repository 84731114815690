import { UPLOADS_API_URL } from "config";
import React from "react";

import { Link } from "react-router-dom";
import { useAdverts } from "../api/getAdverts";
import { useCategories } from "../api/getCategories";
import { useProducts } from "../api/getBooks";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { ImBook } from "react-icons/im";

export const Sidebar = () => {
  const categoriesQuery = useCategories();

  const productsQuery = useProducts();

  const advertsQuery = useAdverts();

  return (
    <>
      <div>
        <div className="my-2 flex items-center justify-between gap-2">
          <h1 className="uppercase text-sm font-black text-green-600">
            Categories
          </h1>
          <div className="flex gap-1">
            <div className="py-1 px-3 rounded-full bg-green-500"></div>
            <div className="py-1 px-1 rounded-full bg-gray-500"></div>
            <div className="py-1 px-1 rounded-full bg-gray-500"></div>
          </div>
        </div>
        {/* categories */}
        <div className="px-4 py-2 shadow-md rounded-lg bg-white">
          <ul className="text-sm mt-5 ">
            {categoriesQuery.data?.map((category, index) => (
              <li key={index} className="mb-2">
                <Link
                  className="flex items-center text-lg gap-3 cursor-pointer hover:text-green-600"
                  to={`/categories/${category.slug}`}
                >
                  <img
                    className="w-8 h-8 object-cover"
                    src={
                      `${UPLOADS_API_URL + "/categories/" + category.icon}` ||
                      `https://via.placeholder.com/40x40.png`
                    }
                    loading="lazy"
                    alt="advert"
                  />
                  {category.name}
                </Link>
              </li>
            ))}
            {categoriesQuery.isLoading && (
              <li className="space-y-4 mb-2">
                <div className="h-6 animate-pulse bg-gray-300 rounded w-full"></div>
                <div className="h-6 bg-gray-200 animate-pulse rounded w-full"></div>
              </li>
            )}
          </ul>
        </div>

        {/* Services */}
        <div className=" my-4">
          <div className="my-2 flex items-center justify-between gap-2">
            <h1 className="uppercase text-sm font-black text-green-600">
              Services
            </h1>
            <div className="flex gap-1">
              <div className="py-1 px-3 rounded-full bg-green-500"></div>
              <div className="py-1 px-1 rounded-full bg-gray-500"></div>
              <div className="py-1 px-1 rounded-full bg-gray-500"></div>
            </div>
          </div>
          <div className="px-4 py-2 my-4 shadow-md rounded-lg bg-white">
            <ul className="text-sm my-5 space-y-6">
              <li className="mb-2">
                <Link
                  className="flex items-center text-lg gap-3 cursor-pointer hover:text-green-600"
                  to="/services/bids"
                >
                  <FaMoneyCheckAlt className="w-6 h-6" />
                  Bids
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  className="flex items-center text-lg gap-3 cursor-pointer hover:text-green-600"
                  to="/services/book-evaluation"
                >
                  <ImBook className="w-6 h-6" />
                  Book Evaluation
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {/* image advert */}
        <div className="relative mt-5">
          {advertsQuery.data?.map((advert, index) => {
            if (advert.ad_type === "primary") {
              return (
                <img
                  src={UPLOADS_API_URL + "/adverts/" + advert.image}
                  alt="computing-ad"
                  className="rounded-lg shadow-md w-full object-cover"
                  key={index}
                  loading="lazy"
                />
              );
            } else {
              return <div key={index}></div>;
            }
          })}
          {advertsQuery.isLoading && (
            <div className="h-96 skeleton w-full"></div>
          )}
        </div>

        {/* latest products  */}
        <div className="mt-5 flex items-center justify-between gap-2">
          <h1 className="uppercase text-sm font-black text-green-600">
            Latest Products
          </h1>
          <div className="flex gap-1">
            <div className="py-1 px-3 bg-green-500 rounded-full"></div>
            <div className="py-1 px-1 bg-gray-500 rounded-full"></div>
            <div className="py-1 px-1 bg-gray-500 rounded-full"></div>
          </div>
        </div>

        {/* latest item products */}
        <div className="mt-3 h-fit px-5 bg-white py-1 pb-5 shadow-md rounded-lg">
          <ul className="text-sm mt-5 ">
            {productsQuery.data?.slice(0, 4).map((product, index) => (
              <li key={index} className="mt-4 hover:bg-green-50">
                <Link
                  className="flex items-center gap-3"
                  to={`/products/${product.slug}`}
                >
                  <img
                    className="w-4/12"
                    src={
                      UPLOADS_API_URL +
                      "/products/" +
                      product?.coverImage?.location
                    }
                    alt={product.name}
                    loading="lazy"
                  />
                  <div>
                    <div className="font-bold text-base my-4">
                      <p className="">{product.name.slice(0, 15) + "..."}</p>{" "}
                    </div>
                    <p className="text-red-700 uppercase text-sm font-bold">
                      ugx {product?.amount?.toLocaleString()}
                    </p>
                  </div>
                </Link>
              </li>
            ))}
            {productsQuery.isLoading &&
              [0, 1].map((index) => (
                <div
                  key={index}
                  className="flex gap-3 items-center justify-center my-4"
                >
                  <div className="">
                    <div className="bg-gray-300 animate-pulse rounded h-20 w-24"></div>
                  </div>
                  <div className="flex flex-col space-y-3 w-28 justify-center">
                    <div className="h-6 bg-gray-300 animate-pulse rounded w-full"></div>
                    <div className="h-6 bg-gray-300 animate-pulse rounded w-2/3"></div>
                  </div>
                </div>
              ))}
          </ul>
        </div>

        {/* second image ad  */}
        <div className="relative mt-3">
          {advertsQuery.data?.map((advert, index) => {
            if (advert.ad_type === "secondary") {
              return (
                <img
                  src={UPLOADS_API_URL + "/adverts/" + advert.image}
                  alt="computing-ad"
                  className="rounded-lg shadow-md w-full object-cover"
                  key={index}
                  loading="lazy"
                />
              );
            } else {
              return <div key={index}></div>;
            }
          })}
          {advertsQuery.isLoading && (
            <div className="h-96 skeleton w-full"></div>
          )}
        </div>
      </div>
    </>
  );
};
