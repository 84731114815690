import { ProductItem } from "components/ProductItem/ProductItem";
import React from "react";
import { useProducts } from "../api/getProducts";

export const RelatedProducts = () => {
  const productsQuery = useProducts();

  return (
    <div>
      <div>
        <div className="my-8">
          <p className="font-bold text-xl">More related products</p>
        </div>
        {
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            {productsQuery.data?.slice(0, 4).map((product, index) => (
              <div key={index}>
                <ProductItem product={product} />
              </div>
            ))}
          </div>
        }
      </div>
    </div>
  );
};
