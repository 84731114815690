import React from "react";
import { Link } from "react-router-dom";
import logo from "assets/logo.png";
import { FiPhoneCall } from "react-icons/fi";

export default function Topbar({ page }) {
  return (
    <>
      {/* <div className="w-full bg-orange-400 p-2 text-center font-bold">
        <span className="text-white">
          Eshop is still in development. Please do not use your real credit
          card.
        </span>
      </div> */}
      <div className="bg-white">
        <div className=" max-w-screen-xl mx-auto">
          <div className="flex items-center justify-between mx-4 md:mx-10 pt-4 py-2 max-w-screen-xl">
            <div className="flex items-center">
              <Link to="/">
                <img
                  src={logo}
                  alt="ncdc eshop logo"
                  className="w-20"
                  loading="lazy"
                />
              </Link>
            </div>

            <a href="tel:+256779930071" className="flex items-center gap-2">
              <FiPhoneCall className="text-green-600 text-lg" />
              <h1 className="text-green-700 text-xs">
                Hotline: +256 779930071
              </h1>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
