/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/accessible-emoji */
import { Button, CoolSpinner } from "components/Elements";
import { Notifications } from "components/Notifications/Notifications";
import { AuthProvider } from "lib/auth";
import { queryClient } from "lib/react-query";
import * as React from "react";
import { useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { HelmetProvider } from "react-helmet-async";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter as Router } from "react-router-dom";

const ErrorFallback = () => {
  useEffect(() => {
    // clear localstorage on error
    localStorage.clear();
  });
  return (
    <>
      <div
        className="text-gray-800 w-full h-full flex flex-col justify-center items-center"
        role="alert"
      >
        <div className="rounded-md p-10">
          <h2 className="text-lg font-bold mb-2 flex flex-row space-x-2">
            <span>Something went wrong.</span>
          </h2>
          <h2 className="text-xs font-semibold text-gray-400">
            It's probably a minor error which has been reported. Contact support
            if issue persists.
          </h2>
          <Button
            className="mt-4"
            onClick={() => {
              localStorage.clear();
              window.location.assign(window.location.origin);
            }}
          >
            Home
          </Button>
        </div>
      </div>
    </>
  );
};

export const AppProvider = ({ children }) => {
  return (
    <React.Suspense
      fallback={
        <div className="fixed w-full h-full bg-white flex justify-center items-center">
          <CoolSpinner />
        </div>
      }
    >
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <HelmetProvider>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              {process.env.NODE_ENV !== "test" && <ReactQueryDevtools />}
              <Notifications />
              <Router>{children}</Router>
            </AuthProvider>
          </QueryClientProvider>
        </HelmetProvider>
      </ErrorBoundary>
    </React.Suspense>
  );
};
