import { MainLayout } from "components/Layout";
import React from "react";
import { OrderItems } from "./orderItems";

export const ServiceOrder = () => {
  return (
    <>
      <MainLayout>
        <div>
          <div className="flex flex-wrap">
            <div className="w-screen md:w-full">
              <div className="shadow-lg">
                <div className="flex items-center justify-between px-5 py-4 bg-white rounded-md ">
                  <div className="font-extrabold md:text-xl">Confirm Order</div>
                </div>
              </div>
              <div className="relative mt-2 flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg ">
                <div className="px-4 py-5 flex-auto">
                  <div className="tab-content tab-space">
                    <div className="block">
                      <div>
                        <OrderItems />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};
