import { Button } from "components/Elements";
import { Form, InputField } from "components/Form";
import { useAuth } from "lib/auth";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import * as z from "zod";

// import { seedUser } from '../api/seedUser';

const schema = z.object({
  email: z.string().min(1, "Required"),
  password: z.string().min(1, "Required"),
});

export const LoginForm = ({ onSuccess }) => {
  const { login, isLoggingIn } = useAuth();

  return (
    <div>
      <Form
        onSubmit={async (values) => {
          await login(values);
          onSuccess();
        }}
        schema={schema}
      >
        {({ register, formState }) => (
          <>
            <InputField
              type="email"
              label="Email Address"
              error={formState.errors["email"]}
              registration={register("email")}
            />
            <InputField
              type="password"
              label="Password"
              error={formState.errors["password"]}
              registration={register("password")}
            />
            <div>
              <Button
                isLoading={isLoggingIn}
                type="submit"
                className={`w-full ${
                  isLoggingIn ? "bg-green-400" : "bg-green-600"
                }`}
              >
                {isLoggingIn ? "Logging in..." : "Login"}
              </Button>
            </div>
          </>
        )}
      </Form>
      <div className="mt-2 flex items-center justify-between text-sm">
        <div>
          <Link
            to="/auth/register"
            className="font-medium text-green-600 hover:text-green-500"
          >
            Create new account
          </Link>
        </div>
        <div>
          <Link
            to="/auth/forgot-password"
            className="font-medium text-green-600 hover:text-green-500"
          >
            Forgot Password
          </Link>
        </div>
      </div>
    </div>
  );
};

LoginForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};
