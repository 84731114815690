import { MainLayout } from "components/Layout";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useProducts } from "../api/getProducts";
import { ProductItem } from "components/ProductItem/ProductItem";
import { Pagination } from "components/Elements";

export const LevelProducts = () => {
  const { slug } = useParams();

  const allProductsQuery = useProducts();
  const productsQuery = allProductsQuery?.data?.filter((product) => {
    return product.level.slug === slug;
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(8);

  // Get current Products
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;

  const currentProducts = productsQuery?.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const pageNumberLimit = 5;
  const [maxPageNumber, setMaxPageNumber] = useState(5);
  const [minPageNumber, setMinPageNumber] = useState(1);

  const paginateFront = () => {
    setCurrentPage(currentPage + 1);
    if (currentPage + 1 > maxPageNumber) {
      setMaxPageNumber(maxPageNumber + pageNumberLimit);
      setMinPageNumber(minPageNumber + pageNumberLimit);
    }
  };
  const paginateBack = () => {
    setCurrentPage(currentPage - 1);
    if ((currentPage - 1) % pageNumberLimit === 0) {
      setMaxPageNumber(maxPageNumber - pageNumberLimit);
      setMinPageNumber(minPageNumber - pageNumberLimit);
    }
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <MainLayout>
      <div className="h-full flex flex-col justify-between">
        <div className="space-y-6">
          <div className="flex px-4 md:px-8 py-5 shadow-lg items-center bg-white rounded-md justify-between">
            <div>
              <h1 className="text-sm md:text-base">
                Category: Books :{" "}
                <span className="font-extrabold ">{slug}</span>
              </h1>
            </div>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
            {currentProducts?.map((product, index) => {
              return (
                <div key={index}>
                  <ProductItem product={product} />
                </div>
              );
            })}

            {/* Skeleton  */}
            {allProductsQuery.isLoading &&
              [0, 1, 2, 3].map((index) => {
                return (
                  <div key={index}>
                    <div className="flex items-center gap-y-3 h-full rounded-lg shadow-md bg-white">
                      <div className="rounded-lg space-y-2 w-full">
                        <div className="skeleton h-48 w-full"></div>
                        <div className="p-4">
                          <div className="skeleton h-6 w-full"></div>
                          <div className="skeleton h-6 w-3/4 my-2"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          {productsQuery?.filter((product) => {
            return product.level.slug === slug;
          }).length === 0 ? (
            <div className="w-full">
              <div className="flex flex-col w-2/3 mx-auto items-center justify-center">
                <p className="my-4 text-2xl">
                  Sorry, we currently don't have any products under this
                  category.
                </p>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="my-8">
          <Pagination
            postsPerPage={productsPerPage}
            totalPosts={productsQuery?.length}
            paginate={paginate}
            currentPage={currentPage}
            paginateFront={paginateFront}
            paginateBack={paginateBack}
            maxPageNumber={maxPageNumber}
            minPageNumber={minPageNumber}
          />
        </div>
      </div>
    </MainLayout>
  );
};
