import { axios } from "lib/axios";
import { useNotificationStore } from "stores/notifications";
import { useMutation } from "react-query";

export const generatePRN = ({ data }) => {
  return axios.post("payments", data);
};

export const useGeneratePRN = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onSuccess: (data) => {
      console.log(data);
      let prn = data.PRN;
      localStorage.setItem("prn", prn);
      addNotification({
        type: "success",
        title: "PRN has been generated",
      });
    },
    ...config,
    mutationFn: generatePRN,
  });
};
