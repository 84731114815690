import { Spinner } from "components/Elements";
import { MdDeleteOutline } from "react-icons/md";
import { useDeleteWishlistItem } from "../api/deleteWishlist";

export const RemoveWishlist = ({ wishlistItemId }) => {
  const deleteWishlistMutation = useDeleteWishlistItem();
  return (
    <button
      onClick={async () =>
        await deleteWishlistMutation.mutateAsync({
          wishlistItemId,
        })
      }
      className=""
    >
      {deleteWishlistMutation.isLoading ? (
        <Spinner />
      ) : (
        <div className="flex border border-green-500 items-center bg-transparent px-2 py-1 text-green-500">
          <MdDeleteOutline className="text-xl " />
          <span>Remove</span>
        </div>
      )}
    </button>
  );
};
