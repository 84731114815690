import { useAuth } from "lib/auth";
import React, { useState } from "react";
import { BsArrowLeftShort } from "react-icons/bs";
import { ImCancelCircle } from "react-icons/im";
import { Link } from "react-router-dom";
import { useAddOrder } from "../api/addOrder";
import { Spinner } from "components/Elements";

export const OrderItems = ({ cartItems, cartId }) => {
  //get user details
  const { user } = useAuth();

  //add order
  const addOrderMutation = useAddOrder();

  //get current date
  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;

  //calculate total cost
  var totalNetCost = 0;
  var totalGrossCost = 0;

  cartItems.forEach((product) => {
    totalGrossCost =
      totalGrossCost + (product.cost_price + product.tax) * product.quantity;
    totalNetCost = totalNetCost + product.cost_price * product.quantity;
  });

  // handle the notification
  const [show, setShow] = useState(true);

  const handleClick = () => {
    setShow(false);
  };

  return (
    <div className="">
      {/* Account details  */}
      <div className="py-5 mt-5 flex flex-col-reverse md:flex-row  justify-between">
        <div>
          <p className="font-extrabold mt-2">
            Name: <span className="font-light">{user.name}</span>
          </p>
          <p className="font-extrabold mt-2">
            Phone number: <span className="font-light">+256 789 438456 </span>
          </p>
          <p className="font-extrabold mt-2">
            Date: <span className="font-light">{date}</span>
          </p>
          {/* <p className="font-extrabold mt-2">
            Order number:
            <span className="text-green-500 font-extrabold">J120922456</span>
          </p> */}
        </div>
        <div
          className={
            show === false
              ? "hidden"
              : "bg-gray-100 py-1.5 px-3 md:w-3/12 mb-3 md:mb-0"
          }
        >
          <div className="flex items-center justify-between">
            <h1 className="uppercase">Note </h1>
            <ImCancelCircle
              className={"cursor-pointer"}
              onClick={handleClick}
            />
          </div>
          <p className="flex flex-wrap text-sm mt-3">
            Please confirm the details before payment.
            <br />
            <br /> Thanks for shopping with us
          </p>
        </div>
      </div>

      {/* Product summary */}
      <div>
        <div className="flex flex-col md:w-7/12">
          <div className="overflow-x-auto ">
            <div className="py-2 inline-block min-w-full ">
              <div className="overflow-hidden">
                <table className="min-w-full">
                  <thead className="border-b ">
                    <tr>
                      <th
                        scope="col"
                        className="text-sm  text-gray-900 md:px-6 py-4 text-left font-extrabold"
                      >
                        Items
                      </th>
                      <th
                        scope="col"
                        className="text-sm  text-gray-900 md:px-6 py-4 text-left font-extrabold"
                      >
                        Qty
                      </th>
                      <th
                        scope="col"
                        className="text-sm  text-gray-900 md:px-6 py-4 text-left font-extrabold"
                      >
                        Cost
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {cartItems?.map((item, index) => (
                      <tr key={index} className="border-b">
                        <td className="md:px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {item.product.name.length > 25
                            ? item.product.name.slice(0, 22) + "..."
                            : item.product.name}
                        </td>
                        <td className="text-sm text-gray-900 font-light md:px-6 py-4 whitespace-nowrap">
                          {item.quantity}
                        </td>
                        <td className="text-sm text-gray-900  md:px-6 py-4 whitespace-nowrap font-extrabold">
                          UGX
                          {(item.cost_price * item.quantity).toLocaleString()}
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td
                        colSpan="2"
                        className="md:px-6 py-4 whitespace-nowrap  font-extrabold text-gray-900"
                      >
                        Total
                      </td>
                      <td className=" text-gray-900  md:px-6 py-4 whitespace-nowrap font-extrabold">
                        UGX {totalGrossCost.toLocaleString()}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-5 mb-5 flex justify-end items-center">
        <div className="flex gap-10">
          <Link to="/cart">
            <button className="flex items-center gap-1 hover:text-green-600">
              <BsArrowLeftShort className="text-xl" />
              Back to cart
            </button>
          </Link>
        </div>

        {/* Add an order */}
        <button
          onClick={async () => {
            localStorage.setItem("totalCost", totalGrossCost);
            await addOrderMutation.mutateAsync({
              data: {
                cart_id: cartId,
                paymentMethod: "mtn",
              },
            });
          }}
          className="bg-green-500 px-5 py-2 m-4 text-white rounded"
        >
          {addOrderMutation.isLoading ? (
            <Spinner size="sm" />
          ) : (
            <>
              <span>Pay now</span>
            </>
          )}
        </button>
      </div>
    </div>
  );
};
