import { MDPreview, Spinner } from "components/Elements";
import { MainLayout } from "components/Layout";
import { UPLOADS_API_URL } from "config";
import React, { useEffect } from "react";
import { MdFavoriteBorder } from "react-icons/md";
import { Link, useParams } from "react-router-dom";
import { useProduct } from "../api/getProduct";
import { useAddWishlist } from "../api/addWishlist";
import { useAddToCart } from "../api/addToCart";
import { useAuth } from "lib/auth";
import { RelatedProducts } from "../components/RelatedProducts";
// import { useWishlist } from "features/wishlist/api/getWishlist";

export const Product = () => {
  const auth = useAuth();

  const { slug } = useParams();
  const productQuery = useProduct({ slug });
  const addToWishlistMutation = useAddWishlist();
  const addToCartMutation = useAddToCart();

  // const getWishList = useWishlist();

  // var response;
  // if (auth.user && auth.user.email_verified_at) {
  //   response = getWishList?.data?.wishlistItems?.filter((wishList) => {
  //     return wishList?.product_id === productQuery?.data?.id;
  //   });
  // }

  // scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // add product to storage
  if (productQuery.data) {
    // create viewed products array in local storage
    if (!localStorage.getItem("viewedProducts")) {
      localStorage.setItem("viewedProducts", JSON.stringify([]));
    }
    // add product_id & view count to viewed products array in local storage
    const viewedProducts = JSON.parse(localStorage.getItem("viewedProducts"));
    const viewedProduct = {
      product_id: productQuery.data.id,
      name: productQuery.data.name,
      amount: productQuery.data?.amount,
      image: productQuery.data?.coverImage?.location,
      slug: productQuery.data.slug,
      view_count: 1,
    };

    if (viewedProducts) {
      const productIndex = viewedProducts.findIndex(
        (product) => product.product_id === productQuery.data.id
      );

      if (productIndex === -1) {
        viewedProducts.push(viewedProduct);
        localStorage.setItem("viewedProducts", JSON.stringify(viewedProducts));
      } else {
        viewedProducts[productIndex].view_count++;
        localStorage.setItem("viewedProducts", JSON.stringify(viewedProducts));
      }
    }
  }

  return (
    <MainLayout title={productQuery?.data?.name}>
      <div className="shadow-md bg-white rounded-lg mt-8">
        {/* Skeleton */}
        {productQuery.isLoading && (
          <div className="p-8">
            <div className="flex p-8">
              <div className="skeleton h-48 w-36"></div>
              <div>
                <div className="skeleton h-8 w-1/2 my-4"></div>
                <div className="skeleton h-8 w-2/3 my-4"></div>
              </div>
            </div>
            <div className="">
              <div className="skeleton h-8 w-full my-4"></div>
              <div className="skeleton h-8 w-3/4 my-4"></div>
              <h1 className="font-extrabold">Product description</h1>
              <div className="skeleton h-8 w-2/4 my-4"></div>
            </div>
          </div>
        )}

        {/* Loaded data */}
        {!productQuery.isLoading && (
          <div className="">
            <div className="px-5 py-5 w-full">
              <div className="w-full md:flex">
                <div className="md:flex md:w-7/12 items-center">
                  <div className="md:w-2/6 w-full flex justify-center ">
                    <img
                      src={
                        UPLOADS_API_URL +
                        "/products/" +
                        productQuery.data?.coverImage?.location
                      }
                      alt="..."
                      className="w-4/6"
                      loading="lazy"
                    />
                  </div>
                  <div className="md:w-4/6 py-5 px-5">
                    <h1 className="text-xl font-extrabold mb-2 text-green-700">
                      {productQuery.data?.name}
                    </h1>
                    <span className="font-extrabold uppercase text-red-700 text-xl ">
                      ugx {productQuery.data?.amount?.toLocaleString()}
                    </span>
                  </div>
                </div>
                <div className="flex justify-end md:w-5/12 py-5 px-5">
                  {auth.user && auth.user.email_verified_at ? (
                    <div className="flex flex-row md:flex-col gap-4 md:gap-10 justify-evenly ">
                      <button
                        type="submit"
                        onClick={async () =>
                          await addToCartMutation.mutateAsync({
                            data: {
                              product_id: productQuery.data?.id,
                              quantity: 1,
                            },
                          })
                        }
                        className="px-4 py-2 rounded-lg shadow-sm bg-green-500 text-white font-bold"
                      >
                        {addToCartMutation.isLoading ? (
                          <Spinner size="sm" />
                        ) : (
                          <span>Add to Cart</span>
                        )}
                      </button>
                      {
                        <button
                          onClick={async () =>
                            await addToWishlistMutation.mutateAsync({
                              data: {
                                product_id: productQuery.data?.id,
                              },
                            })
                          }
                          className="text-green-500 rounded-lg shadow-sm border border-green-500 font-extrabold px-5 py-1.5 "
                        >
                          {addToWishlistMutation.isLoading ? (
                            <Spinner size="sm" />
                          ) : (
                            <div className="flex items-center gap-2 ">
                              <MdFavoriteBorder className="text-xl text-green-500" />
                              <span>Save Item</span>
                            </div>
                          )}
                        </button>
                      }
                    </div>
                  ) : (
                    <div>
                      <Link to="/auth/login">
                        <button className="text-green-500 rounded-lg shadow-sm border border-green-500 font-extrabold px-5 py-1.5 ">
                          Log in and start shopping
                        </button>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-10 px-5">
                <div className="flex gap-5">
                  {productQuery.data?.images.length > 1 &&
                    productQuery.data?.images?.map((image, index) => (
                      <div className="cursor-pointer" key={index}>
                        <img
                          src={UPLOADS_API_URL + "/" + image.name}
                          alt="..."
                          loading="lazy"
                        />
                      </div>
                    ))}
                </div>
              </div>
              <div className="my-4 mx-5 font-extrabold text-lg">
                {productQuery.data?.downloadable === 1 ? (
                  <p>This product is available in soft-copy</p>
                ) : (
                  <p>This product is not available in soft-copy</p>
                )}
              </div>
              <div className="mt-5">
                <div className="mx-5">
                  <div>
                    <h1 className="font-extrabold">Product description</h1>
                    <div className="mt-2 font-light flex flex-wrap text-sm">
                      {productQuery.data?.description && (
                        <MDPreview value={productQuery.data.description} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Related Products */}
      {!productQuery.isLoading && <RelatedProducts />}
    </MainLayout>
  );
};
