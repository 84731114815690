import { useAuth } from "lib/auth";
import React from "react";
import { Home, Lock } from "react-feather";
import { BsCart2 } from "react-icons/bs";
import { MdFavoriteBorder, MdKeyboardArrowDown } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { useCategories } from "../api/getCategories";

export const MobileSidebar = ({ page }) => {
  const auth = useAuth();

  const categoriesQuery = useCategories();
  const navigate = useNavigate();

  //handle onSelect
  function onSelect(e) {
    if (e.target.value === "All Categories") {
      navigate(`/`);
    } else {
      navigate(`/categories/${e.target.value}`);
    }
  }

  return (
    <div>
      <div className="mx-4 my-2 w-full space-y-4">
        <div className="my-2 md:hidden flex">
          <p className="text-green-600 py-2 px-2 text-sm flex items-center rounded-sm gap-2">
            <Link to="/" className="hover:text-white flex space-x-2">
              <Home />
              <span>Home</span>
            </Link>
          </p>
        </div>

        {auth.user ? (
          <>
            <div>
              <div className="flex justify-left text-sm px-2 py-1 items-center">
                <div>
                  <div className="dropdown relative inline-block text-left">
                    <button
                      className="inline-flex w-full text-green-600 rounded-md border-none py-2 text-md font-medium text-gray-700  hover:bg-gray-50 focus:outline-none"
                      type="button"
                      id="menuDropdown"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <div className="flex items-center mr-2 justify-center h-6 w-6 rounded-full bg-green-500 text-white text-xs font-medium">
                        {auth.user.name[0]}
                      </div>
                      {auth.user.name}
                      <MdKeyboardArrowDown className="-mr-1 ml-2 h-5 w-5" />
                    </button>
                    <ul
                      className="dropdown-menu min-w-max absolute hidden bg-white text-md z-50 float-left py-2 list-none text-left 
                      rounded-lg shadow-lg mt-1 hidden m-0 bg-clip-padding border-none"
                      aria-labelledby="menuDropdown"
                    >
                      <li>
                        <Link
                          to="/profile"
                          className="dropdown-item text-gray-900 block px-4 py-2 text-md"
                        >
                          Profile
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/profile/orders"
                          className="dropdown-item text-gray-900 block px-4 py-2 text-md"
                        >
                          Orders
                        </Link>
                      </li>
                      <li onClick={() => auth.logout()}>
                        <Link
                          to="/"
                          className="dropdown-item text-gray-900 block px-4 py-2 text-md"
                        >
                          Sign out
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <Link to="/wishlist" className={page === "wishlist" ? "" : ""}>
              <div className="text-sm text-green-600 py-2 px-2 text-md flex items-center rounded-sm gap-2">
                <MdFavoriteBorder className="text-green-600  text-xl font-bold cursor-pointer" />
                <h1 className="text-green-600 cursor-pointer">Wishlist</h1>
              </div>
            </Link>
            <Link to="/cart" className={page === "cart" ? "" : ""}>
              <div>
                <div className="text-sm text-green-600 py-2 px-2 text-md flex items-center rounded-sm gap-2">
                  <BsCart2 className="text-green-600 font-bold  text-xl cursor-pointer" />
                  <h1 className="text-green-600 cursor-pointer">My Cart</h1>
                </div>
              </div>
            </Link>
          </>
        ) : (
          <Link to="/auth/login" className={page === "login" ? "" : ""}>
            <div className="flex lg:hidden text-green-600 py-2 px-2 text-sm flex items-center rounded-sm gap-2">
              <Lock className="text-green-600 font-bold cursor-pointer" />
              <h1 className="text-green-600 cursor-pointer">
                Login / Register
              </h1>
            </div>
          </Link>
        )}

        <div className="text-green-500 text-sm flex flex-col rounded-sm gap-2">
          <div className="flex items-center text-white bg-green-500 py-3 px-4">
            <p>Services</p>
          </div>
          <div className="mx-3 my-2 space-y-4">
            <Link to="/services/bids">
              <p>Bids</p>
            </Link>
            <Link to="/services/book-evaluation">
              <p>Book Evaluation</p>
            </Link>
          </div>
        </div>

        <div className="text-white bg-green-500 py-2 px-2 flex items-center rounded-sm gap-2">
          <select
            className="block w-full px-2 py-1 text-sm border rounded transition ease-in-out
              m-0 border-none focus:outline-none bg-green-500"
            aria-label="form-select-sm"
            onChange={onSelect}
          >
            <option value="All Categories" className="text-black">
              Categories
            </option>
            {categoriesQuery?.data?.map((category, index) => {
              return (
                <option
                  key={index}
                  value={category.slug}
                  className="text-black"
                >
                  {category.name}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    </div>
  );
};
