import React from "react";
import { useBanners } from "../api/getBanners";
import { UPLOADS_API_URL } from "config";
import { Link } from "react-router-dom";
import { useSpecialSales } from "../api/getSpecialSales";

export default function Carousel() {
  const bannersQuery = useBanners();
  const specialSalesQuery = useSpecialSales();

  return (
    <div>
      <div className="w-full grid grid-cols-1 gap-4 lg:grid-cols-3">
        <div className="col-span-2">
          {bannersQuery.data?.slice(0, 1).map((banner, index) => (
            <div
              key={index}
              className="grid grid-cols-2 items-center bg-white rounded-lg shadow-md"
            >
              <img
                src={
                  UPLOADS_API_URL +
                    "/products/" +
                    banner?.coverImage.location ||
                  banner?.product?.images[0].location
                }
                alt={banner.product?.slug}
                loading="lazy"
                className="w-5/6 h-full md:h-72 md:w-full rounded-l-lg object-cover"
              />
              <div className="px-2 md:px-8 py-2 md:py-4 w-full flex items-start flex-col">
                <h1 className="text-md text-gray-700 md:text-2xl font-bold uppercase font-homepage">
                  {banner.title}
                </h1>
                <span className="mt-1 uppercase text-green-600 font-bold text-sm md:text-base">
                  {banner.product?.name}
                </span>

                <h1 className="md:mt-4 font-extrabold uppercase text-red-700 text-lg md:text-2xl font-homepage">
                  ugx {banner.product?.amount?.toLocaleString()}
                </h1>

                <Link
                  to={`/products/${banner.product?.slug}`}
                  className="mt-1 md:mt-5 py-2 md:py-4 px-5 bg-[#232F3F] w-full h-fit text-white
                   rounded-full text-center uppercase font-extrabold md:text-2xl font-homepage hover:bg-green-900"
                >
                  Shop Now
                </Link>
              </div>
            </div>
          ))}
          {/* Skeleton */}
          {bannersQuery.isLoading && (
            <div className="grid grid-cols-2 gap-4 items-center bg-white rounded-lg shadow-md">
              <div className="skeleton w-full h-72"></div>
              <div className="w-full flex items-start flex-col space-y-6 px-4">
                <div className="skeleton h-10 w-full"></div>
                <div className="mt-1 skeleton h-8 w-2/3"></div>
                <div className="skeleton h-6 w-2/3"></div>
              </div>
            </div>
          )}
        </div>
        <div className="w-full flex flex-col md:flex-row lg:flex-col gap-3">
          {specialSalesQuery.data?.slice(0, 2).map((sale, index) => {
            return (
              <Link
                to={`/products/${sale.product?.slug}`}
                key={index}
                className="bg-white py-3 grid grid-cols-6 px-2 gap-2 rounded-md shadow-md hover:bg-green-50"
              >
                <div className="col-span-4 flex flex-col justify-center px-5">
                  <span className="bg-[#FFB800] text-black text-xs md:text-md rounded-full py-1 px-4 w-fit font-bold">
                    Special Sale
                  </span>
                  <span className="font-extrabold my-2">
                    {sale.product?.name.length > 25
                      ? sale.product.name.slice(0, 22) + "..."
                      : sale.product.name}
                  </span>
                  <span className="text-[#9B9191] font-extrabold text-sm uppercase line-through">
                    ugx {sale.product?.amount?.toLocaleString()}
                  </span>
                  <span className="text-red-700 font-extrabold text-lg uppercase font-extrabold">
                    ugx {sale?.discount.toLocaleString()}
                  </span>
                </div>
                <div className="col-span-2 justify-center item-center">
                  <img
                    src={
                      UPLOADS_API_URL +
                      "/products/" +
                      sale.product?.images[0].location
                    }
                    alt={sale.title}
                    className="w-full h-28 object-cover"
                    loading="lazy"
                  />
                </div>
              </Link>
            );
          })}
          {/* Skeleton */}
          {specialSalesQuery.isLoading &&
            [0, 1].map((index) => {
              return (
                <div
                  key={index}
                  className="bg-white py-3 grid grid-cols-6 px-2 gap-2 rounded-md shadow-md hover:bg-green-50"
                >
                  <div className="col-span-4 flex flex-col justify-center px-5">
                    <span className="bg-[#FFB800] text-black text-xs md:text-md rounded-full py-1 px-4 w-fit font-bold">
                      Special Sale
                    </span>
                    <div className="my-2 skeleton h-6 w-full"></div>
                    <div className="my-2 skeleton h-4 w-3/4"></div>
                    {/* <div className="my-2 skeleton h-4 w-3/4"></div> */}
                  </div>
                  <div className="col-span-2 justify-center item-center">
                    <div className="my-2 skeleton h-24 w-full"></div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
