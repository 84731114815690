import { Spinner } from "components/Elements";
import { MainLayout } from "components/Layout";
import { useAuth } from "lib/auth";
import { useState } from "react";
import { useUpdateProfile } from "features/auth/api/updateUser";

export const Profile = () => {
  const { user } = useAuth();
  const updateProfileMutation = useUpdateProfile();

  //user input data
  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [phone, setPhone] = useState("");

  return (
    <MainLayout>
      <div className="my-4 space-y-8">
        <div className="px-8 py-4 shadow-lg rounded-md bg-white">
          <p className="font-bold text-lg">Account Overview</p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="shadow-lg space-y-4 bg-gray-50 rounded-md">
            <div className="bg-white flex justify-between rounded-t-md">
              <p className="text-lg font-bold px-8 py-4">ACCOUNT MANAGEMENT</p>
              <button
                className="text-green-500 px-8 py-4"
                data-bs-toggle="modal"
                data-bs-target="#editModal"
              >
                Edit
              </button>
            </div>
            <div className="space-y-4 px-8 py-4">
              <p>
                <span className="font-bold">Name: </span>
                {user.name}
              </p>
              <p>
                <span className="font-bold">Email: </span>
                {user.email}
              </p>
              <p>
                <span className="font-bold">Phone: </span>
                {user?.phone_number}
              </p>
              {/* <button className="text-red-700 font-bold">Delete Account</button> */}
            </div>
          </div>
          <div className="shadow-lg space-y-4 bg-gray-50">
            <div className="bg-white">
              <p className="text-lg font-bold px-8 py-4">ORDERS</p>
            </div>
            <div className="space-y-4 px-8 py-4">
              {/* <div className="justify-between flex">
                <p className="font-bold">Pending Orders</p>
                <button
                  className="text-green-500"
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#pendingModal"
                >
                  View
                </button>
              </div> */}
              <div className="bg-gray-200 h-0.5 w-full"></div>
              <div className="justify-between flex">
                <p className="font-bold">Closed Orders</p>
                <button
                  className="text-green-500"
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#completedModal"
                >
                  View
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Modal */}
      <div
        className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
        id="editModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog relative w-auto pointer-events-none">
          <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
            <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
              <h5
                className="text-xl font-medium leading-normal text-gray-800"
                id="exampleModalLabel"
              >
                Edit Profile
              </h5>
              <button
                type="button"
                className="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body relative p-4">
              <div className="space-y-4 px-8 py-4">
                <div className="flex space-x-4 items-center">
                  <label>Name: </label>
                  <input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                    className="border border-gray-300 rounded px-4 py-2 w-full"
                    placeholder="Please enter your name"
                  />
                </div>
                <div className="flex space-x-4 items-center">
                  <label>Email: </label>
                  <input
                    value={email}
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    className="border border-gray-300 rounded px-4 py-2 w-full"
                    placeholder="Please enter your email"
                  />
                </div>
                <div className="flex space-x-4 items-center">
                  <label>Phone number: </label>
                  <input
                    type="text"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    className="border border-gray-300 rounded px-4 py-2 w-full"
                    placeholder="Please enter your Phone number"
                  />
                </div>
                <button
                  onClick={async () =>
                    await updateProfileMutation.mutateAsync({
                      data: {
                        name: name,
                        email: email,
                        role: "User",
                      },
                    })
                  }
                  className="px-4 py-2 bg-green-700 my-4 text-white rounded"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  {updateProfileMutation.isLoading ? (
                    <Spinner />
                  ) : (
                    <span>Submit</span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Pending Orders Modal */}
      <div
        className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
        id="pendingModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog relative w-auto pointer-events-none">
          <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
            <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
              <h5
                className="text-xl font-medium leading-normal text-gray-800"
                id="exampleModalLabel"
              >
                Pending Orders
              </h5>
              <button
                type="button"
                className="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body relative p-4">
              Pending Orders appear here
            </div>
          </div>
        </div>
      </div>

      {/* Completed Orders Modal */}
      <div
        className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
        id="completedModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog relative w-auto pointer-events-none">
          <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
            <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
              <h5
                className="text-xl font-medium leading-normal text-gray-800"
                id="exampleModalLabel"
              >
                Completed Order
              </h5>
              <button
                type="button"
                className="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body relative p-4">
              Completed Orders appear here
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
