import { UPLOADS_API_URL } from "config";
import React from "react";
import { Link } from "react-router-dom";

export const ProductItem = ({ product }) => {
  return (
    <div className="h-[18rem] md:h-[23]rem] lg:h-[26rem]">
      <Link
        className="flex items-center gap-y-3 h-full rounded-lg shadow-md bg-white hover:bg-green-50"
        to={`/products/${product?.slug}`}
      >
        <div className="rounded-lg space-y-2 w-full">
          <img
            src={UPLOADS_API_URL + "/products/" + product?.coverImage?.location}
            alt=".."
            loading="lazy"
            className="h-32 lg:h-72 w-full object-cover rounded-tr-lg rounded-tl-lg"
          />
          <div className="p-4">
            <p className="text-red-600">
              UGX {product?.amount?.toLocaleString()}
            </p>
            <p className="font-bold my-2">
              {product?.name?.length > 25
                ? product?.name?.slice(0, 22) + "..."
                : product?.name}
            </p>
            <p className="font-bold text-gray-600 text-sm my-2">
              {product?.level?.name.length > 25
                ? product?.level?.name.slice(0, 22) + "..."
                : product?.level?.name}
            </p>
          </div>
        </div>
      </Link>
    </div>
  );
};
