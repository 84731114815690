import { MainLayout } from "components/Layout";
import { useParams } from "react-router-dom";
import { Books } from "../components/Books";
import { OtherProducts } from "../components/OtherProducts";

export const Products = () => {
  const { slug } = useParams();

  return (
    <MainLayout page={slug}>
      <div className="space-y-6">
        <div className="flex px-4 md:px-8 py-5 items-center justify-between">
          <div>
            <h1 className="text-sm md:text-base">
              Category: <span className="font-extrabold ">{slug}</span>
            </h1>
          </div>
        </div>
        <div className="w-full">
          {slug === "books" ? (
            //display book levels and products
            <Books />
          ) : (
            //display other products
            <OtherProducts slug={slug} />
          )}
        </div>
      </div>
    </MainLayout>
  );
};
