import clsx from "clsx";

import { FieldWrapper } from "./FieldWrapper";

export const InputField = (props) => {
  const {
    type = "text",
    label,
    className,
    placeholder,
    registration,
    error,
  } = props;
  return (
    <FieldWrapper label={label} error={error}>
      <input
        type={type}
        // maxLength={maxLength}
        placeholder={placeholder}
        className={clsx(
          "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm",
          className
        )}
        {...registration}
      />
      {/* <div className="my-1 text-xs flex justify-end">{caption}</div> */}
    </FieldWrapper>
  );
};
