import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Layout } from "../components/Layout";
import { RegisterForm } from "../components/RegisterForm";

export const Register = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout title="Register your account">
      <RegisterForm onSuccess={() => navigate("/verify")} />
    </Layout>
  );
};
