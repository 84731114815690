import React from "react";
import { Link } from "react-router-dom";
import { Visa } from "./Visa";
import { MobileMoney } from "./MobileMoney";
import { Spinner } from "components/Elements";

export const PaymentMethods = () => {
  const [openTab, setOpenTab] = React.useState("Mobile money");

  return (
    <>
      <div>
        <div>
          <ul className="flex space-x-8 justify-center mb-2 list-none py-4 flex-row">
            <li className="">
              <button
                className={`text-lg rounded-md px-4 py-2 ${
                  openTab === "Mobile money"
                    ? " bg-green-800  text-white"
                    : "text-black border-2 border-green-400 hover:bg-green-500"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(`Mobile money`);
                }}
              >
                Mobile Money
              </button>
            </li>
            <li className="">
              <button
                className={`text-lg rounded-md px-4 py-2 ${
                  openTab === "Visa"
                    ? " bg-green-800 text-white"
                    : "text-black border-2 border-green-400 hover:bg-green-500"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(`Visa`);
                }}
              >
                Visa
              </button>
            </li>
          </ul>
        </div>
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6  rounded ">
          <div className="px-4 py-5 flex-auto">
            {openTab === "Visa" ? (
              <Visa />
            ) : openTab === "Mobile money" ? (
              <MobileMoney />
            ) : (
              " "
            )}
          </div>
        </div>
        <div className="mt-5 mb-5 flex justify-end items-center">
          <Link to="/receipt">
            <button className="bg-green-500 mx-4 text-white px-5 py-2 font-extrabold rounded">
              View receipt
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};
