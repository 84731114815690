import React from "react";

export const MobileMoney = () => {
  return (
    <div className="space-y-8">
      <div className="md:w-1/2 mx-auto text-lg">
        <p>Please enter the following codes in your phone</p>
        <ul className="my-4">
          <li>Dial *165# MTN Mobile Money Menu</li>
          <li>Select 4 Payments</li>
          <li>Select 4 Goods and Services</li>
        </ul>
      </div>
    </div>
  );
};
