import { MainLayout } from "components/Layout";
import React, { useRef, useState } from "react";
import { ImCancelCircle } from "react-icons/im";
import { Link } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

export const Receipt = () => {
  const receiptRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => receiptRef.current,
    documentTitle: "emp-data",
  });
  return (
    <MainLayout>
      {/* Heading card  */}
      <div className="shadow-lg my-4">
        <div className="rounded-md px-5 py-4 bg-white">
          <div className="font-extrabold md:text-xl">Receipt</div>
        </div>
      </div>
      <div className=" rounded shadow-lg px-8 py-6 bg-white" ref={receiptRef}>
        {/* Account details  */}
        <div className="py-5 mt-5 flex flex-col-reverse md:flex-row  justify-between">
          <div>
            <p className="font-extrabold mt-2">
              Name: <span className="font-light">John Doe</span>
            </p>
            <p className="font-extrabold mt-2">
              Phone number: <span className="font-light">+256 789 438456 </span>
            </p>
            <p className="font-extrabold mt-2">
              Date: <span className="font-light">14/23/2034</span>
            </p>
            <p className="font-extrabold mt-2">
              Order number:
              <span className="text-green-500 font-extrabold">J120922456</span>
            </p>
          </div>
        </div>

        {/* Product summary */}
        <div>
          <div className="flex flex-col md:w-7/12">
            <div className="overflow-x-auto ">
              <div className="py-2 inline-block min-w-full ">
                <div className="overflow-hidden">
                  <table className="min-w-full">
                    <thead className="border-b ">
                      <tr>
                        <th
                          scope="col"
                          className="text-sm  text-gray-900 md:px-6 py-4 text-left font-extrabold"
                        >
                          Items
                        </th>
                        <th
                          scope="col"
                          className="text-sm  text-gray-900 md:px-6 py-4 text-left font-extrabold"
                        >
                          Qty
                        </th>
                        <th
                          scope="col"
                          className="text-sm  text-gray-900 md:px-6 py-4 text-left font-extrabold"
                        >
                          Cost
                        </th>
                      </tr>
                    </thead>
                    {/* <tbody>
                    {cartItems?.map((item, index) => (
                      <tr key={index} className="border-b">
                        <td className="md:px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {item.product.name.length > 25
                            ? item.product.name.slice(0, 22) + "..."
                            : item.product.name}
                        </td>
                        <td className="text-sm text-gray-900 font-light md:px-6 py-4 whitespace-nowrap">
                          {item.quantity}
                        </td>
                        <td className="text-sm text-gray-900  md:px-6 py-4 whitespace-nowrap font-extrabold">
                          UGX
                          {(item.cost_price * item.quantity).toLocaleString()}
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td
                        colSpan="2"
                        className="md:px-6 py-4 whitespace-nowrap  font-extrabold text-gray-900"
                      >
                        Total
                      </td>
                      <td className=" text-gray-900  md:px-6 py-4 whitespace-nowrap font-extrabold">
                        UGX {totalGrossCost.toLocaleString()}
                      </td>
                    </tr>
                  </tbody> */}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-8 flex justify-end">
          <button
            className="bg-green-500 text-white px-5 py-2 font-extrabold"
            onClick={handlePrint}
          >
            Print Receipt
          </button>
        </div>
      </div>
    </MainLayout>
  );
};
